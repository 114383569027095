.card:hover .main-image {
  opacity: 0;
}
.card:hover .new {
  z-index: 10;
}
.card .main-image {
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}
.card {
  position: relative;
}

.hover-image {
  opacity: 0;
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.card:hover .hover-image {
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-filter: saturate(100%);
  filter: saturate(100%);
}
