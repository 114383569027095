html,
body {
  overflow-x: hidden;
  height: 100%;
  width: 100% !important;
  box-sizing: border-box;
  padding: 0 !important;
}

.carousel-control-prev {
  display: none !important;
}
.carousel-control-next {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  background-color: rgb(234 88 12);
  box-shadow: inset 0 0 5px rgb(255, 255, 255);
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  box-shadow: inset 0 0 5px grey;
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(234 88 12);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(234 88 12);
}

@layer base {
  h1 {
    @apply text-2xl;
  }
}
@layer components {
  .btn-primary {
    @apply inline-block px-6 mx-1 py-2.5 bg-gray-200 text-orange-700 font-medium text-xs leading-tight  rounded-full shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-secondary {
    @apply inline-block mx-1 px-4 py-2.5 bg-orange-600 text-white font-medium text-xs leading-tight  rounded-full shadow-md hover:bg-orange-500 hover:shadow-lg focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out;
  }
  .nav-link {
    @apply text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100;
  }

  .dropdown-container {
    @apply min-w-max absolute bg-white text-base z-50 float-left py-2 top-11 list-none text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none left-auto right-0;
  }
}
